import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Discover from "./Discover";
import Phonebook from './Phonebook';
import Profile from './Profile';
import Nav from './Nav';
import Settings from './Settings'

const App = () => {
  return (
    <div className="App">
      <Nav/>
        <Router>
          <Switch>
            <Route path="/" exact component={Discover}/>
            <Route path="/discover" exact component={Discover}/>
            <Route path="/phonebook" exact component={Phonebook}/>
            <Route path="/profile" exact component={Profile}/>
            <Route path="/settings" exact component={Settings}/>
          </Switch>
        </Router>
      </div>
  );
}

export default App;
