import React from 'react';

const UserCard = ({user}) => {
    
    // const [users, setUsers] = useState({
    //     fullName: "Iraj Patel",
    //     username: "iraj",
    //     pfp: "",
    //     bio: "",
    // })

	return (
		<div className="UserCard">
			<div className="UCContents">
				<img src={ user.pfp } className="UCProfilePicture" alt={user.fullName}/>
				<div className="UCNameDiv">
					<h1 className="UCFullName">{ user.fullName }</h1>
					<h3 className="UCUsername">@{ user.username }</h3>
				</div>
				<button className={user.username !== "iraj" ? "UCAddButton" : "UCAddButton--disabled"}>+ Add</button>
				<p className="UCBio">{ user.bio }</p>
				<div className="UCWidgets">
					<p></p>
				</div>
			</div>
		</div>
	)
}

export default UserCard;