import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Link,
    Switch,
    Redirect
} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faUser, faSearch, faStream, faCog } from '@fortawesome/free-solid-svg-icons'

const Nav = () => {
  return (
    <div className="Nav">
        <ul>
            <a href="/discover"><FontAwesomeIcon icon={faSearch}/></a>
        </ul>
        <ul>
            <a href="/phonebook"><FontAwesomeIcon icon={faStream}/></a>
        </ul>
        <ul>
            <a href="/profile"><FontAwesomeIcon icon={faUser}/></a>
        </ul>
        <ul>
            <a href="/settings"><FontAwesomeIcon icon={faCog}/></a>
        </ul>
    </div>
  );
}

export default Nav;
