import React from 'react';
import UserPreviewCard from './UserPreviewCard';

class Phonebook extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            users: [{
                fullName: "Iraj Patel",
                username: "iraj",
                pfp: "",
                bio: "",
                id: "1",
            },
            {
                fullName: "Test Account",
                username: "Test",
                pfp: "",
                bio: "",
                id: "2",
            },
            {
                fullName: "Test Account 2",
                username: "Test_2",
                pfp: "",
                bio: "",
                id: "3",
            },
            {
                fullName: "Test Account 3",
                username: "Test_3",
                pfp: "",
                bio: "",
                id: "4",
            },
            {
                fullName: "Test Account 4",
                username: "Test_4",
                pfp: "",
                bio: "",
                id: "5",
            },
            {
                fullName: "Test Account 5",
                username: "Test_5",
                pfp: "",
                bio: "",
                id: "6",
            },
            {
                fullName: "Test Account 6",
                username: "Test_6",
                pfp: "",
                bio: "",
                id: "7",
            },
            {
                fullName: "Test Account 7",
                username: "Test_7",
                pfp: "",
                bio: "",
                id: "8",
            }]
        }
        // fetch('https://us-central1-network-a0a05.cloudfunctions.net/api/users').then(res => res.json()).then(users => this.setState({users: users}))
    }

    // componentDidMount() {
    //     fetch('/api/users').then(res => res.json()).then(users => this.setState({users: users}))
    // }
    
    render() {
        return (
            <div className="Phonebook">
                <div className="Header">
                    <input placeholder="Search" className="Search"/>
                </div>
                <div className="UPCList">
                    {this.state.users.map(user => (<UserPreviewCard user={user} key={(user.id !== undefined) ? user.id : ""} />))}
                </div>
            </div>
        )
    }
}

export default Phonebook;