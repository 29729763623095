import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const UserPreviewCard = ({user}) => {
    
    // const [users, setUsers] = useState({
    //     fullName: "Iraj Patel",
    //     username: "iraj",
    //     pfp: "",
    //     bio: "",
    // })

	return (
		<div className="UserPreviewCard">
			<div className="UPCContents">
				<img src={ user.pfp } className="UPCProfilePicture" alt={user.fullName}/>
				<div className="UPCNameDiv">
					<h3 className="UPCFullName">{ user.fullName }</h3>
					<p className="UPCUsername">{ user.username !== undefined ? `@${user.username}` : "" }</p>
				</div>
				<button className={user.username !== "iraj" ? "UPCAddButton" : "UPCAddButton--disabled"}><FontAwesomeIcon icon={faPlus}/></button>
			</div>
		</div>
	)
}

export default UserPreviewCard;