import React from 'react';
import UserCard from './UserCard';


const Settings = () => {
    
    // API -> Auth User -> AuthedUser = "me"
    const me = {
        fullName: "Iraj Patel",
        username: "iraj",
        pfp: "",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Velit ut tortor pretium viverra suspendisse potenti nullam ac. Diam vulputate ut pharetra sit amet aliquam id diam. Porttitor lacus luctus accumsan tortor posuere ac ut.",
        id: "1",
    }

	return (
		<div className="Settings">
            <h1>Settings</h1>
            <p>This is a React Frontend Demonstration App, no backend features available :)</p>
		</div>
    )
}

export default Settings;