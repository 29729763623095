import React from 'react';
import UserCard from './UserCard';


const Profile = () => {
    
    // API -> Auth User -> AuthedUser = "me"
    const me = {
        fullName: "Iraj Patel",
        username: "iraj",
        pfp: "",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Velit ut tortor pretium viverra suspendisse potenti nullam ac. Diam vulputate ut pharetra sit amet aliquam id diam. Porttitor lacus luctus accumsan tortor posuere ac ut.",
        id: "1",
    }

	return (
		<div className="Profile">
            <div className="Header">
			    {/* <input placeholder="Search" className="Search"/> */}
            </div>
            <div className="MyUserCard">
                <UserCard user={me} key={me.id}/>
            </div>
		</div>
    )
}

export default Profile;